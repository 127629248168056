@font-face {
  font-family: "RobotoSlab-Bold";
  src: url("./fonts/RobotoSlab-Bold.ttf");
}
@font-face {
  font-family: "openSans";
  src: url("./fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "gotham";
  src: url("./fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Raleway-Black";
  src: url("./fonts/Raleway-Black.ttf");
}
@font-face {
  font-family: "Raleway-Light";
  src: url("./fonts/Raleway-Light.ttf");
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("./fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "gotham-Bold";
  src: url("./fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "openSans-Light";
  src: url("./fonts/OpenSans-Light.ttf");
}

html {
  width: 100% !important;
  height: auto !important;
  scroll-behavior: smooth;
}

div[class$="-singleValue"] {
  color: #7189a8 !important;
}

.redcolor {
  color: red !important;
}

.LoadingContainer {
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10005;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-align-right {
  margin-right: 110px !important;
}
a {
  outline: 0;
  text-decoration: none;
  padding: 5px 8px 5px 8px;
}
button,
input,
select,
textarea {
  outline: 0;
}

img,
input {
  border: none;
  margin: 0;
}
.form_row {
  margin-bottom: 15px;
}
.form_row:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}