body {
  scroll-behavior: smooth !important;
}
.fixed_header {
  width: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.landingPage {
  min-width: 970px;
  padding-top: 100px;
}

.landing_page_top_header {
  width: 100%;
  background-color: #223444;
}

.landing_page_top_header_container {
  width: 970px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.landing_page_top_header p {
  font-family: "gotham", "openSans";
  font-weight: normal;
  color: #c1cbdc;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.landing_page_top_header i {
  color: #c1cbdc;
  font-size: 25px;
  margin-right: 7px;
}

.landing_page_top_header_button {
  border-radius: 3px;
  color: #fff;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  padding: 9px;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #f2b330;
  border: 2px solid #f2b330;
  transition: all ease-in-out 200ms;
  cursor: pointer;
}

.landing_page_top_header_button:hover {
  border-radius: 3px;
  color: #fff;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  padding: 9px;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #e19d0f;
  border: 2px solid #e19d0f;
  transition: all ease-in-out 200ms;
  cursor: pointer;
}

.landing_page_top_header_bleu {
  border-radius: 3px;
  color: #fff;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  padding: 9px;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #00b1d6;
  border: 2px solid #00b1d6;
  transition: all ease-in-out 200ms;
  cursor: pointer;
  margin-right: 10px;
}

.landing_page_top_header_bleu:hover {
  border-radius: 3px;
  color: #fff;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  padding: 9px;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: #008ba7;
  border: 2px solid #008ba7;
  transition: all ease-in-out 200ms;
  cursor: pointer;
  margin-right: 10px;
}

.landing_page_bottom_header {
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(208, 208, 208, 0.5) !important;
  -moz-box-shadow: 0px 3px 5px 0px rgba(208, 208, 208, 0.5) !important;
  box-shadow: 0px 3px 5px 0px rgba(208, 208, 208, 0.5) !important;
}

.landing_page_bottom_header_container {
  width: 970px;
  display: flex;
  margin: auto;
  align-items: center;
}
.offert_logo {
  float: right;
  width: 100px;
  vertical-align: middle;
  cursor: pointer;
}

.offert_logo span {
  font-family: "Raleway-Regular", "openSans";
  color: #7b97aa;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 14px;
}

.landing_page_logo img {
  margin-top: 15px !important;
  height: 50px;
  border-right: 1px solid #dbe7f0;
  padding-right: 10px;
}

.landing_page_logos {
  display: flex;
  align-items: baseline;
  margin-right: 32px;
}

.landing_page_logo {
  cursor: pointer;
}

.navbar {
  color: #7b97aa;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.navbar:hover {
  color: #30597d;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}
.scroll {
  color: #30597d;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}
.sliderOut {
  color: #30597d;
  font-family: "gotham", "openSans";
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.landing_page_body {
  background-color: #f8fbfe;
  height: 800px;
  width: 100%;
  z-index: -1;
  background-image: url(../../images/wrapper_accueil.png);
  background-repeat: no-repeat;
  background-position-x: 60%;
  text-align: center;
  padding-top: 60px;
}
.ordonnance_illustration {
  width: 470px;
  margin: auto;
  border: 6px solid #e5eef7;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 30px;
}
.ordonnance_illustration img {
  border: none;
  width: 460px;
}
.landing_page_presentation {
  display: inline-block;
  width: 100%;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}
.landing_page_presentation_bloc {
  width: 970px;
  display: flex;
  margin: auto;
}
.landing_page_presentation_bloc_left {
  width: 970px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #cfd9e8;
}
.landing_page_presentation_bloc_right {
  width: 970px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
}
.clanding_page_presentation_title_left {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.clanding_page_presentation_title_left h1 {
  font-size: 26px;
  font-family: "Raleway-Light", "openSans";
  font-weight: normal;
  color: #30597d;
  text-transform: uppercase;
  text-align: left;
  display: inherit;
  line-height: 31px;
  margin-bottom: 0px;
}
.clanding_page_presentation_title_left .sep_title {
  height: 2px;
  width: 57px;
  background-color: #cfd9e8;
  display: inherit;
  margin-top: 20px;
  float: left;
}

.landing_page_presentation_bloc_right p {
  font-family: "openSans";
  color: #8291a9;
  font-size: 14px;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 30px;
}
.Landing_page_icons {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
/* .Landing_page_icons i {
    font-size: 38px;
    margin-top: 8px;
    margin-right: 6px;
    color: #8291a9;
  } */
.Landing_page_icons p {
  font-family: "openSans";
  color: #8291a9;
  font-size: 14px;
  text-align: left;
  margin-left: 10px;
}
.landing_page_presentation_bloc_left ul {
  margin-top: 30px;
}
.landing_page_acceuil_avantages {
  width: 100%;
  height: auto;
  background-color: #f8fbfe;
  padding-bottom: 60px;
}
.landing_page_acceuil_avantages_carde {
  width: 970px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
}
.avantage_bloc {
  text-align: center;
}
.avantage_bloc h4 {
  text-align: center;
  margin-bottom: 20px !important;
  font-family: "gotham", "openSans" !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
}
.avantage_bloc p {
  text-align: center;
  margin-top: 20px !important;
  font-family: "openSans";
  color: #8291a9;
  font-size: 14px;
}
.doctor_icon {
  width: 56px;
  height: 66px;
  margin-bottom: 20px;
}
.arrow_icon {
  width: 56px;
  height: 27px;
}
.Footer {
  width: 100%;
  background-color: #223444;
}
.Landing_page_footer {
  width: 970px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Landing_page_footer_Brand {
  width: 970px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}
.Footer h4 {
  font-family: "gotham-Bold";
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  padding-left: 27px;
  margin-bottom: 18px;
  margin-top: 40px;
}
.Brand {
  background-color: #172735;
  color: #d4e6f8;
  font-size: 13px;
}
.Brand p {
  font-family: "OpenSans";
  color: #d4e6f8;
  font-size: 13px;
  text-align: left;
  line-height: 17px;
}
.BrandParagrphe {
  margin-left: 60px;
}
.facebook {
  width: 14px;
  height: 15px;
  margin-left: 8px;
}
.FooterdescriptionPargaraphe {
  font-size: 14px !important;
  font-family: "openSans-Light" !important;
  color: #aab6ca;
  font-weight: normal !important;
  margin-top: 20px;
}
.ImageEmail {
  max-width: 100% !important;
  width: auto !important;
}

.footerContact li {
  list-style-type: none;
  display: flex;
  align-items: center;
}
.footerSolutions li {
  float: left;
  list-style-type: none;
}
.footerSolutions li a {
  font-family: "openSans-Light";
  font-size: 14px;
  font-weight: normal;
  color: #aab6ca;
  text-decoration: none;
}
.footerSolutions li a:hover {
  font-family: "openSans-Light";
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  text-decoration: none;
}
.footerContact li p {
  font-family: "openSans-Light";
  font-size: 14px;
  font-weight: normal;
  color: #aab6ca;
  text-decoration: none;
}
.footerContact img {
  width: 28px;
  height: 25px;
  margin-right: 6px;
}
.sobrusLogoFooter {
  margin-right: 20px;
  font-family: "openSans-Light" !important;
  color: #d4e6f8;
  font-size: 13px !important;
}
.col-4 {
  width: 33.333%;
}
.col-2 {
  width: 20.5%;
}
.col-3 {
  width: 25%;
}
.col-9 {
  width: 75%;
}
.footer_Brand {
  display: flex;
  align-items: center;
}
.footer_Brand p {
  font-family: "openSans";
  font-size: 14px;
  font-weight: normal;
  color: #8291a9;
  float: right;
  margin-right: 10px;
}

.Landing_page_howitwork_wrapper {
  width: 100%;
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
}
.Landing_page_howitwork_container {
  width: 970px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.how_blocs {
  display: flex;
  justify-content: space-between;
  height: 260px;
  width: 100%;
  margin-top: 50px;
  border-radius: 5px;
}
.how_patient {
  width: 33.4%;
  background-color: #f2b330;
  border-radius: 5px 0px 0px 5px;
  height: 100%;
  padding: 18px;
}
.how_number {
  float: right;
  height: 33px;
  width: 33px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}
.how_patient h4,
.how_product h4,
.how_imprimer h4 {
  font-family: "Raleway-black", "openSans";
  color: #fff;
  font-size: 16px;
  float: left;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 15px;
  line-height: 19px;
}
.how_blocs_sep {
  width: 57px;
  height: 2px;
  background-color: #ffffff;
  float: left;
}
.how_patient p,
.how_product p,
.how_imprimer p {
  font-family: "openSans";
  color: #fff;
  font-size: 14px;
  float: left;
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
  margin-top: 10px;
}
.how_blocs i,
.how_product i,
.how_imprimer i {
  font-size: 56px;
  right: 50px;
  top: 20px;
  color: #fff;
  margin-bottom: 20px;
}
.how_product {
  float: left;
  display: inline-block;
  width: 33.33%;
  background-color: #8dc73f;
  border-radius: 0px 0px 0px 0px;
  height: 100%;
  padding: 18px;
}
.how_imprimer {
  float: right;
  display: inline-block;
  width: 33.33%;
  background-color: #f23766;
  border-radius: 0px 5px 5px 0px;
  height: 100%;
  padding: 18px;
}
.Landing_page_howitwork_container h1 {
  font-size: 26px;
  font-family: "Raleway-Light", "openSans";
  font-weight: normal;
  color: #30597d;
  text-transform: uppercase;
  text-align: center;
  display: inherit;
  line-height: 31px;
}
.Landing_page_howitwork_container h1 strong {
  font-size: 26px;
  font-family: "Raleway-Black", "openSans";
  font-weight: bold;
  color: #30597d;
}
.Landing_page_howitwork_container .sep_title {
  height: 2px;
  width: 57px;
  background-color: #cfd9e8;
  display: inherit;
  margin-top: 20px;
  vertical-align: middle;
  margin-right: auto;
  margin-left: auto;
}
.Landing_page_howitwork_paragraphe {
  font-family: "openSans";
  color: #8291a9;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  line-height: 19px;
}

.clanding_page_presentation_title_left strong {
  font-size: 26px;
  font-family: "Raleway-black", "openSans";
  font-weight: bold;
  color: #30597d;
  text-transform: uppercase;
  text-align: left;
  display: inherit;
}
.clanding_page_presentation_title_left strong {
  font-size: 26px;
  font-family: "Raleway-black", "openSans";
  font-weight: bold;
  color: #30597d;
  text-transform: uppercase;
  text-align: left;
  display: inherit;
}
.Landing_page_demande_wrapper {
  width: 100%;
  background-color: #f8fbfe;
  padding-bottom: 40px;
  padding-top: 40px;
}
.Landing_page_demande_Container {
  width: 970px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}
.Landing_page_demande_about_left {
  width: 970px;
}
.Landing_page_demande_about_left h1 {
  font-size: 26px;
  font-family: "Raleway-Light", "openSans";
  font-weight: normal;
  color: #30597d;
  text-transform: uppercase;
  text-align: left;
  display: inherit;
  line-height: 31px;
  margin-bottom: 0px;
}
.Landing_page_demande_about_left .title_left .sep_title {
  height: 2px;
  width: 57px;
  background-color: #cfd9e8;
  display: inherit;
  margin-top: 0px;
}
.Landing_page_demande_about_left h1 strong {
  font-size: 26px;
  font-family: "Raleway-Black", "openSans";
  font-weight: bold;
  color: #30597d;
}

.Landing_page_demande_about_left p {
  font-family: "openSans";
  color: #8291a9;
  font-size: 14px;
  text-align: left;
  margin-left: 0px;
  margin-right: 50px;
  margin-top: 10px;
  line-height: 19px;
}
.Landing_page_demande_UL li {
  display: flex;
}
.Landing_page_demande_about_right {
  width: 970px;
}
.Landing_page_demande_about_icon {
  font-size: 22px;
  color: #ddeaf7;
  margin-top: 10px;
  margin-right: 10px;
}
.how_number span {
  font-family: "gotham-bold", "openSans";
  text-align: center;
  display: inherit;
  margin-top: 7px;
  color: #fff;
  font-size: 16px;
}
.footerSolutions ul {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
}
.footerLinks:hover {
  color: white;
  padding: 5px 30px 5px 0px !important;
  cursor: pointer;
}
.footerLinks {
  color: #8291a9;
  padding: 5px 30px 5px 0px !important;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .landing_page_top_header_container {
    width: 600px;
  }
  .landing_page_bottom_header_container {
    width: 100%;
  }
  .landing_page_presentation_bloc {
    width: 100%;
  }
  .landing_page_presentation_bloc_left {
    width: 100%;
  }
  .landing_page_presentation_bloc_right {
    width: 100%;
  }
  .landing_page_acceuil_avantages_carde {
    width: 100%;
  }
  .Landing_page_footer {
    width: 100%;
  }
  .Landing_page_footer_Brand {
    width: 100%;
  }
  .Landing_page_howitwork_container {
    width: 100%;
  }
  .Landing_page_howitwork_container {
    width: 100%;
  }
  .Landing_page_demande_Container {
    width: 100%;
  }
  .Landing_page_demande_about_left {
    width: 100%;
  }
  .Landing_page_demande_about_right {
    width: 100%;
  }
}
.titlesToParagraphes {
  padding-top: 10px;
  padding-bottom: 10px;
}
.titlesToParagraphes h2 {
  font-size: 20px;
  font-family: "Raleway-Black", "openSans";
  color: #30597d;
  display: inherit;
}
.titlesToParagraphes p {
  font-size: 14px !important;
  font-family: "openSans" !important;
  color: #30597d;
  font-weight: normal !important;
  margin-top: 20px;
}
.privacyPolicyContent {
  margin-top: 40px;
  margin-bottom: 40px;
}
.termsOfServiceContent {
  margin-top: 30px;
}
.headlines {
  margin-left: 25px;
}
.FooterdescriptionsubTitle {
  font-size: 15px !important;
  font-family: "OpenSans" !important;
  margin-left: 30px;
  margin-top: 20px;
  color: #30597d;
}

.FooterdescriptionPargaraphe span,
.Landing_page_howitwork_paragraphe span {
  font-family: "gotham-Bold";
  color: #30597d;
}
.form-control {
  display: block;
  width: 96%;
  /* height: 40px; */
  padding: 8px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #7189a8;
  border: 2px solid #d9e3e8;
}
