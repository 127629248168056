.btn_demande {
  width: 100%;
  font-family: "gotham", openSans-serif;
  height: 45px;
  background-color: #f2b330;
  border-radius: 3px;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 12px;
  display: inherit;
  outline: none;
  margin-top: 10px;
  transition: all ease-in-out 200ms;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}
.btn_demande:hover {
  width: 100%;
  font-family: "gotham", "openSans";
  height: 45px;
  background-color: #e19d0f;
  border-radius: 3px;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 12px;
  display: inherit;
  outline: none;
  margin-top: 10px;
  transition: all ease-in-out 200ms;
  cursor: pointer;
}
.MultiStepFormLAbel {
  width: 100%;
  font-family: "gotham-Bold", "openSans";
  color: #30597d !important;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.lr-form h2 {
  font-family: "Roboto Slab", Arial, serif;
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
}
.lr-form h2 strong {
  font-family: "Roboto Slab", Arial, serif;
  font-size: 20px;
}
.lr-form p {
  font-size: 14px;
  text-align: center;
  color: #7189a8;
  margin-bottom: 10px;
}
.lr-form p strong {
  font-size: inherit;
}
